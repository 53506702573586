import React from 'react';
import { Link } from 'react-router-dom';
import wowzaLogo from '../../images/logo-arcast-horizontal.png'

const Nav = () => {

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light pb-3 pb-md-2" id="top-nav">
      <a className="navbar-brand" href="#"><img className="noll"  src={wowzaLogo} alt="ARcast Low Latency Panel" /></a>
      <ul className="navbar-nav mr-auto-lg">
      <li className="nav-item page">
        <Link to="/meeting">
          Reunión
        </Link>
        <span></span>
      </li>{
        /*
        <li className="nav-item page">
            <Link to="/composite">
              Compuesto
            </Link>
            <span></span>
          </li>
          */ }
      </ul>
      <ul className="navbar-nav ml-auto d-none d-md-flex">
        <li className="nav-item mr-3">
          <a href="https://www.arcast.tv/gestion/knowledgebase">Documentación</a>
        </li>
        <li className="nav-item mr-3">
          <a href="https://www.arcast.tv/gestion/submitticket.php">Soporte Técnico</a>
        </li>
      </ul>

    </nav>
  );
}

export default Nav;
